<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain" style="overflow:hidden">
      <div class="userMain">
        <div class="userMainTop"><img src="@/assets/click.png" width="11" />点击查看详情</div>
        <div class="userTool">
          <ul>
            <li v-for="(item,index) in list" :key="index" @click="listClick(index)" :class="{'active': item.active}">
              <div class="userToolBlock">
                <span class="userToolBlockTop">{{item.title}}</span>
                <span class="userToolBlockBottom">{{item.py}}</span>
              </div>
            </li>
            
          </ul>
        </div>
      </div>
      <div class="p20">
        
        <div class="userList" v-if="activeList.length">
          <div class="userListTitle">
            Alto erhu
          </div>
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item,key) in activeList" :key="key">
             <div class="userListBox">
              <div class="pic">
                <img :src="`${$baseUrl}${item.thumb}`" />
                <span class="tips" v-if="item.post">{{item.post}}</span>
              </div>
              <div style="position:relative" v-html="splitName(item)"></div>
              <!-- <h3>黄一鹤<small>Huang Yihe</small></h3> -->
             </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <Footer />

    </div>
    
  </div>
</template>

<style scoped>
 
</style>
<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      tag: '',
      isIndex: false,
      members: [],
      list:[
        {
          title:"打击乐",
          py:"percussion music",
          active:false,
        },
        {
          title:"梆笛",
          py:"bang flute",
          active:false,
        },
        {
          title:"曲笛",
          py:"qu flute",
          active:false,
        },{
          title:"新笛",
          py:"new flute",
          active:false,
        },
        {
          title:"低音唢呐",
          py:"bass suona horm",
          active:false,
        },{
          title:"中音唢呐",
          py:"alto suona hor",
          active:false,
        },{
          title:"高音唢呐",
          py:"high-pitched suona",
          active:false,
        },{
          title:"低倍音笙",
          py:"double bass sheng",
          active:false,
        },{
          title:"中音笙",
          py:"alto sheng",
          active:false,
        },{
          title:"高音笙",
          py:"high-pitched sheng",
          active:false,
        },{
          title:"高音笙",
          py:"bass sheng",
          active:false,
        },{
          title:"古筝",
          py:"guzheng",
          active:false,
        },{
          title:"扬琴",
          py:"dulcimer",
          active:false,
        },{
          title:"琵琶",
          py:"pipa",
          active:false,
        },{
          title:"中阮",
          py:"zhongruan",
          active:false,
        },{
          title:"大阮",
          py:"daruan",
          active:false,
        },{
          title:"大阮/三张",
          py:"daruan/sanzhang",
          active:false,
        },{
          title:"竖琴",
          py:"harp",
          active:false,
        },{
          title:"高胡",
          py:"high-pitched erhu",
          active:false,
        },{
          title:"中胡",
          py:"alto erhu",
          active:false,
        },{
          title:"柳琴",
          py:"liuqin",
          active:false,
        },{
          title:"大提琴",
          py:"cello",
          active:false,
        },{
          title:"低音提琴",
          py:"contras",
          active:false,
        },{
          title:"二胡",
          py:"erhu",
          active:false,
        },
      ]
    }
  },
  computed: {
    activeList() {
      // this.members
      let list = []
      if (this.tag && this.members.length) {
        list = this.members.filter(item=>{
          return item.tags.indexOf(this.tag) > -1
        })
      }
      console.log('tag', list)
      return list
    },
  },
  mounted() {
    this.getMembers()
  },
  methods:{
    listClick(index){
      for(var i =0; i < this.list.length; i++){
        this.list[i].active = false
      }
      this.list[index].active = true
      this.tag = this.list[index].title
    },
    
    getMembers() {
      this.$ajax.get("https://api.sucoch.com/cms/cms/api/member", {}).then((res) => {
        if (res.data && res.data.length) {
          this.members = res.data;
          console.log(this.members)
        }
      });
    },

    splitName(item) {
      if (!item.description) return '';
      let tem = []
      let tem2 = [] 
      let string = ''
      tem = item.description.split('\n')
      tem2 = tem[0].split('（')
      console.log('tag', tem)
      if(tem2.length > 1) {
        tem2[1] = tem2[1].replace('）','');
        string = `<h3 class="cmain fs20 mb4">${tem2[0]}</h3>
        <div class="city">${tem2[1]}</div> 
        <small class="clighter fs16">${tem[1]}</small>
        `
      }else{
        string = `<h3 class="cmain fs20">${tem[0]}</h3>
        <small class="clighter fs16">${tem[1]}</small>
        `
      }
      
      return string
    }
    
  },
}
</script>

